import {
  EvaluationAnswer,
  EvaluationTemplateQuestion,
  EvaluationTemplateQuestionType,
  SaveEvaluationAnswerInput,
} from '../../../../__generated__/graphql';
import { QuestionUpdateValue, QuestionValue, ScorecardValue } from './types';
import {
  findByQuestionAndOptionId,
  findByQuestionId,
  findKeyByQuestionAndOptionId,
  findKeyByQuestionId,
  notEmpty,
} from './utils';

export const mapUpdatedAnswers = (
  answer: EvaluationAnswer,
  answers: EvaluationAnswer[],
  question: EvaluationTemplateQuestion,
) => {
  let answerKey;

  if (
    question.flair__Question_Type__c ===
      EvaluationTemplateQuestionType.Scorecard ||
    question.flair__Question_Type__c ===
      EvaluationTemplateQuestionType.MultipleChoice
  ) {
    answerKey = findKeyByQuestionAndOptionId(
      answers,
      answer.flair__Evaluation_Template_Question__c,
      answer.flair__Evaluation_Template_Option__c,
    );
  } else {
    answerKey = findKeyByQuestionId(answers, question.Id);
  }

  if (answerKey === -1) {
    answers.push(answer);
  } else {
    answers[answerKey] = answer;
  }

  return [...answers];
};

export const getQuestionValue = (
  question: EvaluationTemplateQuestion,
  answers: EvaluationAnswer[],
): QuestionValue => {
  switch (question.flair__Question_Type__c) {
    case EvaluationTemplateQuestionType.FreeText: {
      const answer = findByQuestionId(answers, question.Id);

      return {
        questionType: question.flair__Question_Type__c,
        value: answer && answer.flair__Answer__c ? answer.flair__Answer__c : '',
      };
    }
    case EvaluationTemplateQuestionType.Rating: {
      const answer = findByQuestionId(answers, question.Id);

      return {
        questionType: question.flair__Question_Type__c,
        value: answer && answer.flair__Answer__c ? answer.flair__Answer__c : '',
      };
    }
    case EvaluationTemplateQuestionType.FreeSlider: {
      const answer = findByQuestionId(answers, question.Id);

      return {
        questionType: question.flair__Question_Type__c,
        value:
          answer && answer.flair__Answer__c ? answer.flair__Answer__c : '0',
      };
    }
    case EvaluationTemplateQuestionType.SingleChoice: {
      const answer = findByQuestionId(answers, question.Id);

      return {
        questionType: question.flair__Question_Type__c,
        value: (answer && answer.flair__Evaluation_Template_Option__c) || null,
      };
    }
    case EvaluationTemplateQuestionType.MultipleChoice: {
      const selectedChoices = question.options
        .map((option) => {
          const answer = findByQuestionAndOptionId(
            answers,
            question.Id,
            option.Id,
          );
          return answer && option.Id;
        })
        .filter(notEmpty);

      return {
        questionType: question.flair__Question_Type__c,
        value: selectedChoices,
      };
    }
    case EvaluationTemplateQuestionType.Scorecard:
      const values: ScorecardValue = {};
      question.options.forEach((item) => {
        const answer = findByQuestionAndOptionId(answers, question.Id, item.Id);
        if (answer && answer.flair__Score__c !== null) {
          values[item.Id] = answer.flair__Score__c;
        }
      });

      return {
        questionType: question.flair__Question_Type__c,
        value: values,
      };
  }
  throw new Error(
    `Unsupported question type ${question.flair__Question_Type__c}`,
  );
};

export const mapToAnswerSObject = (
  answer: SaveEvaluationAnswerInput,
  answerId: string,
): EvaluationAnswer => ({
  Id: answerId,
  Name: answerId,
  flair__Question_Type__c: answer.questionType,
  flair__Employee_Evaluation__c: answer.employeeEvaluationId,
  flair__Candidate_Evaluation__c: answer.candidateEvaluationId,
  flair__Evaluation_Template_Question__c: answer.evaluationTemplateQuestion,
  flair__Evaluation_Template_Option__c: answer.evaluationTemplateOption,
  flair__Answer__c: answer.message,
  flair__Score__c: answer.score,
});

export const mapAnswer = (
  employeeEvaluationId: string | null,
  candidateEvaluationId: string | null,
  question: EvaluationTemplateQuestion,
  questionValue: QuestionUpdateValue,
  answers?: EvaluationAnswer[],
): SaveEvaluationAnswerInput => {
  const answer = answers && answers[0];

  switch (questionValue.questionType) {
    case EvaluationTemplateQuestionType.SingleChoice:
      return {
        id: answer ? answer.Id : null,
        questionType: questionValue.questionType,
        employeeEvaluationId: employeeEvaluationId,
        candidateEvaluationId: candidateEvaluationId,
        evaluationTemplateQuestion: question.Id,
        evaluationTemplateOption: questionValue.value,
        message: null,
        score: null,
      };
    case EvaluationTemplateQuestionType.MultipleChoice:
      return {
        id: null,
        questionType: questionValue.questionType,
        employeeEvaluationId: employeeEvaluationId,
        candidateEvaluationId: candidateEvaluationId,
        evaluationTemplateQuestion: question.Id,
        evaluationTemplateOption: questionValue.value,
        message: null,
        score: null,
      };

    case EvaluationTemplateQuestionType.Scorecard:
      const scoreAnswer =
        answers &&
        findByQuestionAndOptionId(answers, question.Id, questionValue.optionId);
      return {
        id: scoreAnswer ? scoreAnswer.Id : null,
        questionType: questionValue.questionType,
        employeeEvaluationId: employeeEvaluationId,
        candidateEvaluationId: candidateEvaluationId,
        evaluationTemplateQuestion: question.Id,
        evaluationTemplateOption: questionValue.optionId,
        message: null,
        score: questionValue.value ?? null,
      };

    case EvaluationTemplateQuestionType.Rating:
    case EvaluationTemplateQuestionType.FreeSlider:
    case EvaluationTemplateQuestionType.FreeText:
      return {
        id: answer ? answer.Id : null,
        questionType: questionValue.questionType,
        employeeEvaluationId: employeeEvaluationId,
        candidateEvaluationId: candidateEvaluationId,
        evaluationTemplateQuestion: question.Id,
        evaluationTemplateOption: null,
        message: questionValue.value,
        score: null,
      };
    default:
      throw new Error(
        `Unsupported question type ${question.flair__Question_Type__c}`,
      );
  }
};
