import { AbsenceCategorySummary, CarryOver, PeriodType } from './types';
import {
  addDateByUnit,
  changeDateByUnit,
  diff,
  parseDate,
  startOfCustomYear,
  UnitTypeArgs,
} from '../../../../../../utils/dateUtils';
import { addDays, addYears, startOfDay, startOfYear } from 'date-fns';

export const getMainPeriodLabelAmount = (
  summary: AbsenceCategorySummary,
  periodType: PeriodType,
): number | null => {
  switch (periodType) {
    case 'next':
      return summary.nextPeriod.upcoming;
    case 'previous':
      return summary.prevPeriod.taken;
    case 'current':
      return summary.curPeriod.available;
  }
};

export const getExpiringCarryOvers = (
  now: Date,
  carryOvers: CarryOver[],
  expireInDaysThreshold: number,
): CarryOver[] => {
  return carryOvers.filter((carryOver) => {
    if (carryOver.expired > 0) {
      // already expired
      return null;
    }
    const expire = expireInDays(now, carryOver);
    if (carryOver.amount - carryOver.used <= 0) {
      // all used, nothing to expire
      return false;
    }
    return expire !== null && expire > 0 && expire < expireInDaysThreshold;
  });
};

export const expireInDays = (
  now: Date,
  carryOver: CarryOver,
): number | null => {
  if (!carryOver.expireOn) {
    return null;
  }
  return Math.max(0, diff(new Date(carryOver.expireOn), now, 'day'));
};

export type Period = { start: Date; end: Date };

export const createYearPeriods = (
  summary: Pick<AbsenceCategorySummary, 'customYearStartDay'>,
  now: Date,
): Period[] => {
  const periodInfo = createPeriodInfo(summary);
  const startOfCurYear = changeDateByUnit(now, periodInfo);
  const cur = {
    start: startOfCurYear,
    end: addDays(addDateByUnit(startOfCurYear, 1, periodInfo.unit), -1),
  };
  const prev = { start: addYears(cur.start, -1), end: addYears(cur.end, -1) };
  const next = { start: addYears(cur.start, 1), end: addYears(cur.end, 1) };
  return [prev, cur, next];
};

export const createPeriodInfo = (
  summary: Pick<AbsenceCategorySummary, 'customYearStartDay'>,
): UnitTypeArgs => {
  const customYearStartDay = summary.customYearStartDay
    ? parseDate(summary.customYearStartDay)
    : undefined;
  return customYearStartDay
    ? {
        unit: 'customYear',
        customYearStartDay,
      }
    : { unit: 'year' };
};

export const getDefaultStartPeriod = (
  now: Date,
  customYearStartDay: Date | undefined,
) => {
  const today = startOfDay(now);
  if (!customYearStartDay) {
    return startOfYear(today);
  }
  return startOfCustomYear(today, customYearStartDay);
};
