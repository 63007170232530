import React, { useCallback, useMemo, useState } from 'react';
import { useToasts } from '../../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../../hooks/useMutationErrorHandler';
import { useNamespacedTranslation } from '../../../../../hooks/useNamespacedTranslation';
import {
  useUpdateWorkflowItemStatusMutation,
  WorkflowItemStatus as WorkflowItemStatusGenerated,
  WorkflowStepActivatingTrigger,
} from '../../../__generated__/graphql';
import { WorkflowItemStatusInfo } from './types';
import { Trans, useTranslation } from 'react-i18next';
import { Column, Row, SortingRule } from 'react-table';
import { EmptyStateCardBody } from '../../../components/EmptyStateCard';
import { TableContent } from '../../../manager/components/TableContent';
import {
  mapToWorkflowTableItem,
  TableWorkflowItem,
} from './WorkflowItemsTable';
import FlairIcon from '../../../../../atomic/atoms/FlairIcon';
import { EmployeeCell } from '../Common/EmployeeCell';
import { employeeSort } from '../Common/sorting';
import { ResponsibleCell } from '../Common/ResponsibleCell';
import CommentCount from '../../../components/Comment/CommentCount/CommentCount';
import { WorkflowItemStatusCell } from './WorkflowItemStatusCell';
import { Theme } from '../../../../../theme';
import { WorkflowStep } from '../types';
import { OverlayTrigger, Popover, ProgressBar } from 'react-bootstrap';
import { WorkflowItemDueDateCell } from './WorkflowItemDueDateCell';
import { WorkflowItemNameCell } from './WorkflowItemNameCell';
import { formatDateShort } from '../../../../../utils/dateUtils';

export type Item = {
  id: string;
  showComments?: boolean;
};

type TableWorkflowStepItem = {
  id: string;
  name: string;
  isStepActive: boolean;
  activatingTrigger: WorkflowStepActivatingTrigger;
  activationDate: string | null;
  itemsCount: number;
  completedItemsCount: number;
};

export type TableItem =
  | ({ tableItemType: 'WorkflowStep' } & TableWorkflowStepItem &
      Partial<Omit<TableWorkflowItem, 'id' | 'name'>>)
  | ({
      tableItemType: 'WorkflowItem';
    } & TableWorkflowItem &
      Partial<Omit<TableWorkflowStepItem, 'id' | 'name'>>);

type Props = Omit<TableProps<TableItem>, 'onStatusChange' | 'updatingItems'>;

export const WorkflowStepsAndItemsTableConnected: React.FC<Props> = (props) => {
  const [updateItemStatus] = useUpdateWorkflowItemStatusMutation();
  const t = useNamespacedTranslation('workflows.workflowItems');
  const { addSuccess } = useToasts();
  const errorHandler = useMutationErrorHandler();

  const [updatingItems, setUpdatingItems] = useState<WorkflowItemStatusInfo[]>(
    [],
  );

  const handleStatusChange = useCallback(
    (info: WorkflowItemStatusInfo) => {
      setUpdatingItems((prev) => [...prev, info]);
      updateItemStatus({
        variables: {
          input: {
            id: info.workflowItemId,
            status: info.status as WorkflowItemStatusGenerated,
          },
        },
      })
        .then(() => {
          addSuccess(t('statusUpdatedToast'));
        })
        .catch(errorHandler)
        .finally(() => {
          setUpdatingItems((prev) =>
            prev.filter((x) => x.workflowItemId !== info.workflowItemId),
          );
        });
    },
    [updateItemStatus, setUpdatingItems, addSuccess, errorHandler, t],
  );

  return (
    <WorkflowStepsAndItemsTable
      {...props}
      onStatusChange={handleStatusChange}
      updatingItems={updatingItems}
    />
  );
};

type WorkflowStepTableItem = WorkflowStep;
export type TableProps<T> = {
  employeeColumnVisible: boolean;
  workflowSteps: WorkflowStepTableItem[];
  onItemClick: (item: Item) => void;
  onStatusChange: (info: WorkflowItemStatusInfo) => void;
  updatingItems: WorkflowItemStatusInfo[];
  initialSortBy?: Array<SortingRule<T>>;
  onSortByChanged?: (sortBy: SortingRule<T>[]) => void;
};

const WorkflowStepsAndItemsTable: React.FC<TableProps<TableItem>> = ({
  workflowSteps,
  employeeColumnVisible,
  onItemClick,
  onStatusChange,
  updatingItems,
  initialSortBy,
}) => {
  const { t } = useTranslation();

  const tableItems: TableItem[] = useMemo(() => {
    return workflowSteps.map((step) => {
      return {
        ...mapToTableStepItem(step),
        subRows: step.workflowItems.map((item) => {
          return {
            tableItemType: 'WorkflowItem',
            ...mapToWorkflowTableItem(item, updatingItems),
          };
        }),
      };
    });
  }, [workflowSteps, updatingItems]);

  const columns: Column<TableItem>[] = useMemo(() => {
    const columnsBeforeFilter: Column<TableItem>[] = [
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.task" />
        ),
        id: 'expander',
        accessor: 'name',
        Cell: ({ row }: { row: Row<TableItem> }) => {
          const { name } = row.original;
          const style = {
            paddingLeft: `${row.depth * 2}rem`,
          };

          if (row.original.tableItemType === 'WorkflowStep') {
            return (
              <div
                className="d-flex"
                {...row.getToggleRowExpandedProps({
                  style,
                })}
                title={name}>
                <FlairIcon
                  className="me-1"
                  icon={
                    row.isExpanded
                      ? 'chevron-down-outline'
                      : 'chevron-forward-outline'
                  }></FlairIcon>
                <div
                  className="text-truncate flex-grow-1"
                  style={{ width: '10rem' }}>
                  {name}
                </div>
              </div>
            );
          } else {
            return (
              <div title={name} className="text-truncate" style={style}>
                <WorkflowItemNameCell
                  name={name}
                  onItemClick={onItemClick}
                  rowId={row.original.id}
                  isMandatory={row.original.isMandatory}
                  workflowStep={row.original.workflowStep}
                />
              </div>
            );
          }
        },
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.workflowAssignee"
          />
        ),
        accessor: 'employee',
        Cell: ({ row }: { row: Row<TableItem> }) => {
          if (row.original.tableItemType === 'WorkflowItem') {
            const employee = row.original.employee;
            return (
              <EmployeeCell
                name={employee.name}
                avatarUrl={employee.avatarUrl}
                position={employee.position ?? undefined}
                department={employee.department ?? undefined}
              />
            );
          }
          return null;
        },
        sortType: (rowA: Row<TableItem>, rowB: Row<TableItem>) => {
          if (
            rowA.original.tableItemType === 'WorkflowItem' &&
            rowB.original.tableItemType === 'WorkflowItem'
          ) {
            return employeeSort(rowA.original.employee, rowB.original.employee);
          } else {
            return 0;
          }
        },
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.responsible"
          />
        ),
        accessor: 'responsible',
        Cell: ({ row }: { row: Row<TableItem> }) => {
          if (row.original.tableItemType === 'WorkflowItem') {
            const responsible = row.original.responsible;
            return <ResponsibleCell value={responsible} />;
          }
          return null;
        },
      },

      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.dueDate" />
        ),
        accessor: 'dueDate',
        Cell: ({ row }: { row: Row<TableItem> }) => {
          if (row.original.tableItemType === 'WorkflowItem') {
            const { dueDate } = row.original;
            return <WorkflowItemDueDateCell dueDate={dueDate} />;
          }
          return null;
        },
      },
      {
        Header: () => (
          <Trans
            t={t}
            i18nKey="workflows.workflowItems.table.header.comments"
          />
        ),
        accessor: 'commentsCount',
        disableSortBy: true,
        Cell: ({ row }: { row: Row<TableItem> }) => {
          if (row.original.tableItemType === 'WorkflowItem') {
            const { commentsCount } = row.original;
            return (
              <CommentCount
                count={commentsCount}
                onClick={() =>
                  onItemClick({ id: row.original.id, showComments: true })
                }
              />
            );
          }
          return null;
        },
      },
      {
        Header: () => (
          <Trans t={t} i18nKey="workflows.workflowItems.table.header.status" />
        ),
        accessor: 'status',
        Cell: ({ row }: { row: Row<TableItem> }) => {
          if (row.original.tableItemType === 'WorkflowItem') {
            const { status } = row.original;
            const readonly = row.original.readonly;
            const id = row.original.id;
            const statusInfo = { workflowItemId: id, status: status };
            const updatingToStatus = row.original.updatingToStatus;
            return (
              <WorkflowItemStatusCell
                value={statusInfo}
                readonly={readonly}
                onChange={onStatusChange}
                updatingToStatus={updatingToStatus}
                isStepActive={row.original.workflowStep.isActive}
              />
            );
          } else {
            if (row.original.isStepActive) {
              return <StepProgressBar step={row.original} />;
            } else {
              return <StepInactiveStatus step={row.original} />;
            }
          }
        },
      },
    ];
    return (
      columnsBeforeFilter
        .filter((col): col is Column<TableItem> => {
          return !(employeeColumnVisible && col.accessor === 'employee');
        })
        //   Disable sorting since it doesn't make sense with the step level
        .map((col) => ({ ...col, disableSortBy: true }))
    );
  }, [t, employeeColumnVisible, onItemClick, onStatusChange]);

  if (!tableItems.length) {
    return <EmptyWorkflowStepsAndItems />;
  }

  const getRowProps = (row: Row<TableItem>) => {
    if (row.original.tableItemType === 'WorkflowStep') {
      return {
        style: {
          backgroundColor: Theme.color.paper6,
        },
      };
    } else {
      return {};
    }
  };

  return (
    <TableContent
      columns={columns}
      data={tableItems}
      initialSortBy={initialSortBy}
      expandAllRows={true}
      getRowProps={getRowProps}
    />
  );
};

export const mapToTableStepItem = (src: WorkflowStepTableItem): TableItem => ({
  tableItemType: 'WorkflowStep',
  id: src.id,
  name: src.name,
  isStepActive: src.isActive,
  activatingTrigger: src.activatingTrigger,
  activationDate: src.activationDate,
  itemsCount: src.workflowItems.length,
  completedItemsCount: src.workflowItems.filter(
    (item) => item.status === 'COMPLETED',
  ).length,
});

const EmptyWorkflowStepsAndItems: React.FC = () => {
  const t = useNamespacedTranslation('workflows.workflowItems.table');
  return <EmptyStateCardBody title={t('empty')} />;
};

const StepProgressBar: React.FC<{ step: TableWorkflowStepItem }> = ({
  step,
}) => {
  const t = useNamespacedTranslation(
    'workflows.workflowItems.workflowStep.progressBar',
  );
  const stepCompleted = step.itemsCount === step.completedItemsCount;
  const stepStarted = step.completedItemsCount > 0;
  const variant = stepCompleted ? 'success' : 'warning';

  const getLabel = () => {
    if (stepCompleted) {
      return t('completed', { itemsCount: step.itemsCount });
    } else if (stepStarted) {
      return t('inProgress', {
        completedCount: step.completedItemsCount,
        itemsCount: step.itemsCount,
      });
    } else {
      return t('notStarted', { itemsCount: step.itemsCount });
    }
  };
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={<Popover id="popover">{getLabel()}</Popover>}>
      <ProgressBar
        className="progress-sm"
        variant={variant}
        now={step.completedItemsCount}
        max={step.itemsCount}
      />
    </OverlayTrigger>
  );
};

const StepInactiveStatus: React.FC<{ step: TableWorkflowStepItem }> = ({
  step,
}) => {
  const t = useNamespacedTranslation(
    'workflows.workflowItems.workflowStep.inactive',
  );

  const getTooltip = () => {
    if (
      step.activatingTrigger ===
      WorkflowStepActivatingTrigger.AfterFinishingMandatoryTasks
    ) {
      return t('tooltip.afterFinishingMandatoryTasks');
    } else if (
      step.activatingTrigger === WorkflowStepActivatingTrigger.SpecificDate &&
      step.activationDate
    ) {
      return t('tooltip.onSpecificData', {
        date: formatDateShort(step.activationDate),
      });
    } else {
      return '';
    }
  };
  return (
    <div className="d-flex text-break">
      <div>
        <FlairIcon
          className="me-2"
          icon={'lock-closed-outline'}
          tooltip={getTooltip()}></FlairIcon>
      </div>
      <div>{t('label')}</div>
    </div>
  );
};
