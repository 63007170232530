import React, { useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FormContext, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import SubmitButton from '../../../../components/form/SubmitButton';
import useYupValidationResolver from '../../../../components/form/useYupValidationResolver';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { Link } from '../../../../Router';
import {
  FieldInput as EmployeeField,
  mapEmployeeSection,
  mapFieldInfo,
  useDefaultValues,
  useFormDataToUpdateFields,
  useValidationSchema,
} from '../../components/EmployeeData';
import { routes } from '../../routes';
import {
  EditPersonalDataQuery,
  useUpdateMyDataMutation,
} from '../../__generated__/graphql';

export type Section = EditPersonalDataQuery['me']['data']['sections'][0];
export type Field = Section['fields'][0];

const Form: React.FC<{ section: Section }> = ({ section }) => {
  const { t } = useTranslation();
  const sectionFields = useMemo(
    () => mapEmployeeSection(section).fields,
    [section],
  );

  const defaultValues = useDefaultValues(sectionFields);

  const validationSchema = useValidationSchema(sectionFields);
  const form = useForm<FormData>({
    defaultValues,
    validationResolver: useYupValidationResolver(validationSchema),
  });

  const [updateMyData] = useUpdateMyDataMutation();
  const errorHandler = useMutationErrorHandler();

  const {
    handleSubmit,
    formState: { dirtyFields },
  } = form;

  const history = useHistory();
  const formDataToInputFields = useFormDataToUpdateFields();
  const onSubmit = (dirtyFields: Set<string>) => async (formData: FormData) => {
    await updateMyData({
      variables: {
        input: {
          fields: formDataToInputFields(sectionFields, formData, dirtyFields),
        },
      },
    })
      .then(() => {
        history.push(routes.personalData.route.create({}));
      })
      .catch(errorHandler);
  };

  return (
    <FormContext {...form}>
      <form onSubmit={handleSubmit(onSubmit(dirtyFields))}>
        <Row className="mb-5">
          {section.fields.map((field, i) => (
            <Col key={i} lg={6}>
              <EmployeeField fieldInfo={mapFieldInfo(field)} />
            </Col>
          ))}
        </Row>

        <hr className="my-5"></hr>

        <Row>
          <Col className="col-auto">
            <SubmitButton>{t('form.general.saveButton')}</SubmitButton>
            <Link
              to={routes.personalData.route}
              className="btn btn-white ms-2 lift">
              {t('form.general.cancelButton')}
            </Link>
          </Col>
        </Row>
      </form>
    </FormContext>
  );
};

export default Form;
