import moment, { Moment } from 'moment';

import { isEqual, omit } from 'lodash';
import {
  TimeSheetStatus,
  WorkloadDayFragment,
} from '../../../../__generated__/graphql';

import {
  DateAndTime,
  DateAndTimeIntervalType,
  TimeEntryBreakItem,
  TimeEntryItem,
  TimeEntryReadOnlyReason,
} from './types';
import { TimeSheetProps } from '../../../../components/TimeSheet/DayCard/types';

export * from './types';

export const getReadonlyReasons = (
  timeSheet: Pick<TimeSheetProps, 'flair__Approval_Status__c'>,
  timeEntries: TimeEntryItem[],
  day: Date,
  restrictFutureEntriesForADay: (day: Date) => boolean,
): TimeEntryReadOnlyReason[] => {
  const reasons: TimeEntryReadOnlyReason[] = [];
  if (isTimesheetReadonly(timeSheet)) {
    reasons.push('timeSheetApproved');
  }
  if (timeEntries[0]?.payrollStatus === 'completed') {
    reasons.push('payrollCompleted');
  }
  if (timeEntries[0]?.payrollStatus === 'locked') {
    reasons.push('payrollLocked');
  }
  if (restrictFutureEntriesForADay(day)) {
    reasons.push('restrictedFutureEntries');
  }
  return reasons;
};

export const isTimesheetReadonly = (
  timeSheet: Pick<TimeSheetProps, 'flair__Approval_Status__c'>,
): boolean => {
  return (
    timeSheet.flair__Approval_Status__c === TimeSheetStatus.Approved ||
    timeSheet.flair__Approval_Status__c === TimeSheetStatus.Skipped
  );
};

export const isMultiday = (
  interval: DateAndTimeIntervalType,
  now: Moment | Date,
): boolean => {
  return (
    interval.start.date !==
    (interval.end !== null ? interval.end.date : toDateStr(now))
  );
};

export const isDirtyTimeEntryItem = (
  initial: TimeEntryItem,
  current: TimeEntryItem,
) => {
  const { breaks, ...currentVal } = current;

  if (initial.isNew) {
    return true;
  }

  return !isEqual(initial, {
    ...currentVal,
    breaks: breaks.map((item) => omit(item, 'isBreakAutoFixed')),
  });
};

export const isNotesDirty = (initial: TimeEntryItem, current: TimeEntryItem) =>
  initial.notes !== current.notes;

export const shouldCreateChangeRequest = (
  initial: TimeEntryItem,
  current: TimeEntryItem,
) => {
  const { breaks, ...currentVal } = current;

  if (initial.isNew) {
    return true;
  }

  const initialWithoutNotes = omit(initial, 'notes');
  const currentWithoutNotes = omit(currentVal, 'notes');

  return !isEqual(initialWithoutNotes, {
    ...currentWithoutNotes,
    breaks: breaks.map((item) => omit(item, 'isBreakAutoFixed')),
  });
};

export const createNewBreak = (
  timeEntry: Pick<TimeEntryItem, 'start' | 'end' | 'breaks'>,
): TimeEntryBreakItem | null => {
  const interval = proposeBreakInterval(timeEntry);
  if (interval === null) {
    return null;
  }
  const [startInterval, endInterval] = interval;
  return createBreak(startInterval, endInterval);
};

export const createBreak = (
  start: Date | Moment,
  end: Date | Moment,
): TimeEntryBreakItem => ({
  uniqueId: start.toString() + '-' + end.toString(),
  isNew: true,
  breakId: null,
  breakChangeRequestId: null,
  start: toDateTime(start),
  end: toDateTime(end),
});

export const createNewTimeEntry = (
  day: Moment | string,
  timeSheetId: string,
  workload?: Pick<WorkloadDayFragment, 'startTime' | 'endTime'> | null,
): TimeEntryItem => {
  const dayStart = moment(day).startOf('day');
  return {
    start: workload?.startTime
      ? toDateTime(createMomentWithTime(day, workload.startTime))
      : toDateTime(dayStart.clone().add(10, 'hours')),
    end: workload?.endTime
      ? toDateTime(createMomentWithTime(day, workload.endTime))
      : toDateTime(dayStart.clone().add(17, 'hours')),
    timeSheetId,
    isMultiday: false,
    notes: '',
    costCenterId: null,
    uniqueId: 'New_' + new Date(),
    timeEntryId: null,
    changeRequestId: null,
    breaks: [],
    isNew: true,
    breaksDeductionStatus: undefined,
  };
};

export const toDateTime = (dateTime: string | Date | Moment): DateAndTime => {
  return {
    date: moment(dateTime).format('YYYY-MM-DD'),
    time: moment(dateTime).format('HH:mm'),
  };
};

// time: HH:mm:ss
const createMomentWithTime = (day: Moment | string, time: string): Moment => {
  const momentTime = moment(time, 'HH:mm:ss');
  const result = moment(day).startOf('day');
  result.add(momentTime.get('hours'), 'hours');
  result.add(momentTime.get('minutes'), 'minutes');
  return result;
};

export const toMomentDateTime = (dateAndTime: DateAndTime): Moment => {
  return moment(`${dateAndTime.date} ${dateAndTime.time}`);
};

export const toDateStr = (dateTime: string | Date | Moment): string => {
  return moment(dateTime).format('YYYY-MM-DD');
};

export const toDateTimeStr = (dateAndTime: DateAndTime): string => {
  return toMomentDateTime(dateAndTime).toISOString();
};

export const calculateDurationInMinutes = ({
  start,
  end,
}: DateAndTimeIntervalType): number => {
  const momentStart = toMomentDateTime(start);
  const momentEnd = end ? toMomentDateTime(end) : moment();
  const duration = moment(momentEnd).diff(momentStart, 'minutes');
  return duration > 0 ? duration : 0;
};

// todo: fix it according to autobreaks
const proposeBreakInterval = (
  timeEntry: Pick<TimeEntryItem, 'start' | 'end' | 'breaks'>,
) => {
  let startInterval: Moment = toMomentDateTime(timeEntry.start);
  if (timeEntry.breaks.length > 0) {
    const lastBreak = timeEntry.breaks[timeEntry.breaks.length - 1];
    if (lastBreak.end === null) {
      return null;
    }
    startInterval = toMomentDateTime(lastBreak.end);
  }

  const endInterval: Moment = timeEntry.end
    ? toMomentDateTime(timeEntry.end)
    : moment();

  const duration = endInterval.diff(startInterval, 'minutes');
  if (duration < 1) {
    return null;
  }

  const BREAK_DURATION = 30;
  if (duration <= BREAK_DURATION) {
    return [startInterval, endInterval];
  }
  const startBreak = startInterval
    .clone()
    .add(Math.ceil((duration - BREAK_DURATION) / 2), 'minutes');
  let endBreak = startBreak.clone().add(BREAK_DURATION, 'minutes');
  endBreak = endBreak.isAfter(endInterval) ? endInterval : endBreak;
  return [startBreak, endBreak];
};

export const calculateTotalDuration = (timeEntries: TimeEntryItem[]) =>
  timeEntries.reduce(
    (acc, curEntry) => acc + calculateTimeEntryDuration(curEntry),
    0,
  );

export const calculateTimeEntryDuration = (
  timeEntry: DateAndTimeIntervalType & { breaks: DateAndTimeIntervalType[] },
): number => {
  const interval = calculateDurationInMinutes(timeEntry);
  if (interval === 0) {
    return interval;
  }
  const breaksSum = timeEntry.breaks.reduce(
    (acc: number, curBreak: DateAndTimeIntervalType) =>
      acc + calculateDurationInMinutes(curBreak),
    0,
  );
  return interval - breaksSum;
};
