import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import React from 'react';
import { ensureApiTokenRefreshed, useAuth } from '../../../context/AuthContext';
import {
  createRequestInfoInApolloOperation,
  addRequestInfoToErrorResponse,
} from '../../../hooks/errorHandlingRequestInfo';
import apolloCacheConfig from './apolloCacheConfig';
import { getApiBaseUrl } from '../../../utils/config';
import { useCurrentLocale } from '../../../context/I18n';

const getAccessTokenLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    ensureApiTokenRefreshed()
      .then((apiToken) => {
        if (!apiToken) {
          throw new Error('Api token should be set here');
        }
        operation.setContext({
          headers: {
            Authorization: apiToken,
          },
        });
        forward(operation).subscribe(observer);
      })
      .catch((error) => {
        console.error('Error while refreshing token', error);
        observer.error(error);
      });
  });
});

const buildClient = (logout: () => void, locale: string) =>
  new ApolloClient({
    name: 'web',
    version: import.meta.env.VITE_APP_CODE_VERSION,
    connectToDevTools: true,
    link: ApolloLink.from([
      new ApolloLink((operation, forward) => {
        createRequestInfoInApolloOperation(operation);
        return forward(operation);
      }),
      onError((errorResponse) => {
        addRequestInfoToErrorResponse(errorResponse);
        const { graphQLErrors } = errorResponse;
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            if (extensions && extensions.code === `UNAUTHENTICATED`) {
              logout();
            }
          });
        }
      }),
      getAccessTokenLink,
      new HttpLink({
        uri: getApiBaseUrl() + '/internal/graphql',
        headers: {
          'flair-locale': locale,
        },
      }),
    ]),
    cache: new InMemoryCache(apolloCacheConfig),
  });

export const Provider: React.FC = ({ children }) => {
  const { isLoggedIn, logout } = useAuth();
  const locale = useCurrentLocale();
  if (!isLoggedIn) {
    logout();
    return <></>;
  }

  const client = buildClient(logout, locale);

  return <ApolloProvider client={client} children={children} />;
};
