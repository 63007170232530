import React, { useCallback, useMemo, useState } from 'react';

import {
  EvaluationAnswer,
  EvaluationTemplate,
  EvaluationTemplateQuestion,
  EvaluationTemplateQuestionType,
  SaveEvaluationAnswerInput,
  useDeleteEvaluationAnswerMutation,
  useSaveEvaluationAnswerMutation,
} from '../../../../__generated__/graphql';

import ScorecardContent from './ScoreCard';
import FreeTextContent from './FreeText';
import FreeSliderContent from './FreeSlider';
import SingleChoiceContent from './SingleChoice';
import MultipleChoiceContent from './MultipleChoice';
import RatingContent from './Rating';
import {
  EvaluationQuestionParams,
  LoadingItem,
  QuestionUpdateValue,
} from './types';
import { debounce } from 'lodash';
import { useMutationErrorHandler } from '../../../../../../hooks/useMutationErrorHandler';
import { findAnswerIds, findByQuestionAndOptionId } from './utils';
import {
  getQuestionValue,
  mapAnswer,
  mapToAnswerSObject,
  mapUpdatedAnswers,
} from './mapping';
import { getRequiredInvalidFields } from '..';
import { IRequiredInvalidFields } from '../../helper';

type Params = Omit<
  EvaluationQuestionParams,
  'defaultValue' | 'loading' | 'onUpdateAnswer'
> & {
  employeeEvaluationId: string | null;
  candidateEvaluationId: string | null;
  question: EvaluationTemplateQuestion;
  answers: EvaluationAnswer[];
  evaluationTemplate: EvaluationTemplate;
  onFieldsUpdate: (fields: IRequiredInvalidFields) => void;
};

const EvaluationQuestion: React.FC<Params> = ({
  question,
  answers,
  disabled,
  employeeEvaluationId,
  candidateEvaluationId,
  evaluationTemplate,
  onFieldsUpdate,
}) => {
  const [lastActiveOptionId, setLastActiveOptionId] = useState<string>('');
  const [questionAnswers, setQuestionAnswers] = useState<EvaluationAnswer[]>(
    answers || [],
  );

  const errorHandler = useMutationErrorHandler();
  const [saveEvaluationAnswer, { loading: loadingSave }] =
    useSaveEvaluationAnswerMutation();
  const [deleteEvaluationAnswer, { loading: loadingDelete }] =
    useDeleteEvaluationAnswerMutation();

  const saveAnswer = useMemo(
    () =>
      debounce(({ answer }: { answer: SaveEvaluationAnswerInput }) => {
        saveEvaluationAnswer({
          variables: {
            input: answer,
          },
        })
          .then((result) => {
            if (!result || !result.data) {
              return;
            }

            const answerSObject = mapToAnswerSObject(
              answer,
              result.data.evaluation.saveEvaluationAnswer.recordId,
            );

            setQuestionAnswers((prevState) => {
              const updatedAnswers = mapUpdatedAnswers(
                answerSObject,
                prevState,
                question,
              );

              onFieldsUpdate(
                getRequiredInvalidFields([question], updatedAnswers),
              );

              return updatedAnswers;
            });
          })
          .catch(errorHandler);
      }, 300),
    [saveEvaluationAnswer, errorHandler, question, onFieldsUpdate],
  );

  const deleteAnswersByOptions = useMemo(
    () =>
      debounce(({ optionIds }: { optionIds: string[] }) => {
        const answerIds = findAnswerIds(optionIds, questionAnswers);

        if (answerIds.length === 0) {
          return;
        }

        deleteEvaluationAnswer({
          variables: {
            ids: answerIds,
          },
        })
          .then(() => {
            setQuestionAnswers(
              questionAnswers.filter(
                (answer) => answerIds.indexOf(answer.Id) === -1,
              ),
            );
          })
          .catch(errorHandler);
      }, 100),
    [deleteEvaluationAnswer, errorHandler, questionAnswers],
  );

  const handleAnswerDelete = useCallback(
    (optionsToDelete) => {
      deleteAnswersByOptions({ optionIds: optionsToDelete });
    },
    [deleteAnswersByOptions],
  );

  const deleteAnswer = useMemo(
    () =>
      debounce((optionId: string) => {
        let answer =
          questionAnswers &&
          findByQuestionAndOptionId(questionAnswers, question.Id, optionId);

        if (!answer) {
          return;
        }

        deleteEvaluationAnswer({
          variables: {
            ids: [answer.Id],
          },
        })
          .then(() => {
            setQuestionAnswers((prevState) => {
              const updatedAnswers = prevState.filter(
                (a) => answer?.Id !== a.Id,
              );

              onFieldsUpdate(
                getRequiredInvalidFields([question], updatedAnswers),
              );

              return updatedAnswers;
            });
          })
          .catch(errorHandler);
      }, 100),
    [
      deleteEvaluationAnswer,
      errorHandler,
      onFieldsUpdate,
      question,
      questionAnswers,
    ],
  );

  const handleAnswerUpdate = useCallback(
    (questionValue: QuestionUpdateValue) => {
      const answer = mapAnswer(
        employeeEvaluationId,
        candidateEvaluationId,
        question,
        questionValue,
        questionAnswers,
      );

      //If value undefined for scorecard, then remove answer
      if (
        questionValue.value === undefined &&
        questionValue.questionType === EvaluationTemplateQuestionType.Scorecard
      ) {
        deleteAnswer(questionValue.optionId);
      } else {
        saveAnswer({
          answer,
        });
      }

      if (
        questionValue.questionType ===
          EvaluationTemplateQuestionType.Scorecard &&
        questionValue.optionId
      ) {
        setLastActiveOptionId(questionValue.optionId);
      }
    },
    [
      employeeEvaluationId,
      candidateEvaluationId,
      question,
      questionAnswers,
      deleteAnswer,
      saveAnswer,
    ],
  );

  return useMemo(() => {
    const questionValue = getQuestionValue(question, questionAnswers);

    switch (question.flair__Question_Type__c) {
      case EvaluationTemplateQuestionType.FreeText:
        return (
          <FreeTextContent
            question={question}
            disabled={disabled}
            loading={loadingSave || loadingDelete}
            onUpdateAnswer={handleAnswerUpdate}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : ''
            }
          />
        );
      case EvaluationTemplateQuestionType.FreeSlider:
        return (
          <FreeSliderContent
            question={question}
            disabled={disabled}
            loading={loadingSave || loadingDelete}
            onUpdateAnswer={handleAnswerUpdate}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : '0'
            }
          />
        );
      case EvaluationTemplateQuestionType.SingleChoice:
        return (
          <SingleChoiceContent
            question={question}
            disabled={disabled}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : null
            }
            loading={loadingSave || loadingDelete}
            onUpdateAnswer={handleAnswerUpdate}
          />
        );
      case EvaluationTemplateQuestionType.MultipleChoice:
        return (
          <MultipleChoiceContent
            question={question}
            disabled={disabled}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : []
            }
            loading={loadingSave || loadingDelete}
            onUpdateAnswer={handleAnswerUpdate}
            onDeleteAnswer={handleAnswerDelete}
          />
        );
      case EvaluationTemplateQuestionType.Rating:
        return (
          <RatingContent
            question={question}
            disabled={disabled}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : ''
            }
            loading={loadingSave || loadingDelete}
            onUpdateAnswer={handleAnswerUpdate}
            ratingMin={evaluationTemplate.flair__Rating_Min__c}
            ratingMax={evaluationTemplate.flair__Rating_Max__c}
            choices={question.options.map((option) => option.Name)}
          />
        );
      case EvaluationTemplateQuestionType.Scorecard:
        const loadingItems: LoadingItem = {};
        if (lastActiveOptionId) {
          loadingItems[lastActiveOptionId] = loadingSave || loadingDelete;
        }

        return (
          <ScorecardContent
            question={question}
            disabled={disabled}
            defaultValue={
              questionValue.questionType === question.flair__Question_Type__c
                ? questionValue.value
                : {}
            }
            loadingItems={loadingItems}
            onUpdateAnswer={handleAnswerUpdate}
          />
        );
      case EvaluationTemplateQuestionType.Unknown:
        return <></>;
      default:
        throw new Error('Invalid Evaluation Question Type');
    }
  }, [
    disabled,
    handleAnswerDelete,
    handleAnswerUpdate,
    lastActiveOptionId,
    loadingDelete,
    loadingSave,
    question,
    questionAnswers,
    evaluationTemplate.flair__Rating_Min__c,
    evaluationTemplate.flair__Rating_Max__c,
  ]);
};

export default EvaluationQuestion;
