import React from 'react';
import { mapWorkflowItemDataField } from './mappings';
import { useWorkflowItemDataRequestQuery } from '../../../__generated__/graphql';
import { WokflowItemDataRequestLoading } from './WorkflowItemDataRequestLoading';
import { WorkflowItemDataRequestForm } from './WorkflowItemDataRequestForm';
import { WorkflowItemDataRequest } from '../WorkflowItems/types';
import ModalSidebar from '../../../components/ModalSidebar';
import { ExtractProps } from '../../../../../utils/react';

type ModalSidebarProps = Pick<ExtractProps<typeof ModalSidebar>, 'onClose'>;

type Props = ModalSidebarProps & {
  workflowId: string;
  workflowItem: WorkflowItemDataRequest;
};

export const WorkflowItemDataRequestComponent: React.FC<Props> = ({
  workflowId,
  workflowItem,
  onClose,
}) => {
  const { data } = useWorkflowItemDataRequestQuery({
    variables: {
      workflowId,
      workflowItemId: workflowItem.id,
    },
  });

  if (!data) {
    return <WokflowItemDataRequestLoading workflowItem={workflowItem} />;
  }

  const dataFields = (data?.workflow.item?.dataFields || []).map(
    mapWorkflowItemDataField,
  );

  return (
    <WorkflowItemDataRequestForm
      workflowItem={workflowItem}
      dataFields={dataFields}
      onClose={onClose}
    />
  );
};
