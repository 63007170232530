import React, { useCallback } from 'react';
import AutoSaveInputLabel from '../../../../../../components/form/AutoSaveInputLabel';
import { FormGroup } from '../../../../../../components/form/FormGroup';
import Hint from '../../../../../../components/hint';
import { EvaluationTemplateQuestionType } from '../../../../__generated__/graphql';
import { EvaluationQuestionParams } from './types';
import {
  Rating as RatingInput,
  RatingInputType,
} from '../../../Questionnaire/QuestionnaireInput/questions/Rating';

type RatingParams = EvaluationQuestionParams & {
  defaultValue: string;
} & Pick<RatingInputType, 'choices' | 'ratingMin' | 'ratingMax'>;

const Rating: React.FC<RatingParams> = ({
  question,
  disabled,
  defaultValue,
  loading,
  onUpdateAnswer,
  choices,
  ratingMin,
  ratingMax,
}) => {
  const handleChange = useCallback(
    (value: string) => {
      onUpdateAnswer({
        questionType: EvaluationTemplateQuestionType.Rating,
        value: value,
      });
    },
    [onUpdateAnswer],
  );

  return (
    <FormGroup>
      <AutoSaveInputLabel
        label={question.flair__Name__c}
        required={question.flair__Required__c}
        loading={loading}
        completed={!!defaultValue}
        hint={
          question.flair__Hint__c && (
            <Hint id={`hint_${question.Id}`} text={question.flair__Hint__c} />
          )
        }
      />
      <RatingInput
        showOptionalComment={false}
        ratingMin={ratingMin}
        ratingMax={ratingMax}
        value={defaultValue}
        onChange={handleChange}
        choices={choices}
        notes=""
        onChangeNotes={() => {}}
        readOnly={disabled || loading}
      />
    </FormGroup>
  );
};

export default Rating;
