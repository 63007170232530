import * as React from 'react';
import { useMemo } from 'react';
import { CandidateEvaluation, CandidateEvaluations } from '../../types';
import { Col, Row } from 'react-bootstrap';
import { useNamespacedTranslation } from '../../../../../../../hooks/useNamespacedTranslation';
import { groupBy } from 'lodash';
import { groupQuestionAndOptions, mapAnswers } from './logic';
import { FeedbackAnswers } from './FeedbackAnswers';
import SidePanelHeaderActions from './SidePanelHeaderActions';
import ModalSidebar from '../../../../../components/ModalSidebar';
import { SwitchDirection } from '../types';
import { EvaluationAnswers } from './types';
import { EvaluationTemplateQuestionType } from '../../../../../__generated__/graphql';
import FlairIcon from '../../../../../../../atomic/atoms/FlairIcon';
import { Theme } from '../../../../../../../theme';
import CommentsPopover from '../../../../../components/Comment/CommentsPopover';
import { RelatedObjectNames } from '../../../../../components/Comment/types';

type GroupedAnswers = {
  [questionId: string]: EvaluationAnswers[];
};

type ContentProps = {
  evaluation: CandidateEvaluation;
};

const i18Path = 'recruitment.candidate.detailsPage';

const Content: React.FC<ContentProps> = ({ evaluation }) => {
  const t = useNamespacedTranslation(i18Path);
  const { questions, options } = groupQuestionAndOptions([evaluation]);

  const answers: GroupedAnswers =
    evaluation.answers && evaluation.answers.length > 0
      ? groupBy(mapAnswers(evaluation.answers), 'templateQuestion')
      : {};

  const overallRecommendationKey = 'overallRecommendation';
  questions[overallRecommendationKey] = t(overallRecommendationKey);
  answers[overallRecommendationKey] = [
    {
      type: 'ONLY_SCORE',
      answer: null,
      score: evaluation.flair__Final_Score__c,
      templateQuestion: overallRecommendationKey,
      templateOption: null,
    },
  ];

  const overallCommentKey = 'overallComments';
  questions[overallCommentKey] = t(overallCommentKey);
  answers[overallCommentKey] = [
    {
      type: EvaluationTemplateQuestionType.FreeText,
      answer: evaluation.flair__Overall_Comment__c,
      score: null,
      templateQuestion: overallCommentKey,
      templateOption: null,
    },
  ];

  return (
    <div className="p-5 pt-0 overflow-auto">
      <Row>
        <Row className="align-items-center">
          <Col className="h1 mb-1">
            {t('employeeFeedback', { employeeName: evaluation.employee.Name })}
          </Col>
          <Col className="d-flex justify-content-end">
            <CommentsPopover
              recordId={evaluation.Id}
              relatedObjectName={RelatedObjectNames.CandidateEvaluation}
              commentsCount={evaluation.commentsCount}
              mode="icon"
            />
          </Col>
        </Row>
        <Row>
          <small>
            {evaluation.evaluationTemplate?.Name ?? t('quickEvaluation')}
          </small>
        </Row>
      </Row>
      <Row className="mt-5 d-flex flex-column gap-5">
        {Object.keys(answers).map((questionId, i) => {
          const questionName = questions[questionId];
          return (
            <Row key={`${questionId}-${i}`}>
              <Col>
                <div className="d-flex align-items-md-baseline">
                  <FlairIcon
                    icon="ellipse-filled"
                    size="sm"
                    className="pe-2"
                    color={Theme.color.blue3}
                  />
                  <span className="h3">{questionName}</span>
                </div>
                <div className="ms-3">
                  <FeedbackAnswers
                    answers={answers[questionId]}
                    options={options}
                    evaluation={evaluation}
                  />
                </div>
              </Col>
            </Row>
          );
        })}
      </Row>
    </div>
  );
};

type Props = {
  evaluations: CandidateEvaluations;
  selectedEvaluationIndex: number;
  setSelectedEvaluationIndex: (index: number) => void;
};

const FeedbackSidebarDetails: React.FC<Props> = ({
  selectedEvaluationIndex,
  setSelectedEvaluationIndex,
  evaluations,
}) => {
  const canSwitchFeedback = useMemo(() => {
    if (selectedEvaluationIndex !== -1) {
      return {
        next: selectedEvaluationIndex < evaluations.length - 1,
        previous: selectedEvaluationIndex > 0,
      };
    } else {
      return {
        next: true,
        previous: true,
      };
    }
  }, [evaluations, selectedEvaluationIndex]);

  const onSwitchFeedback = (direction: SwitchDirection) => {
    if (selectedEvaluationIndex === -1) {
      return;
    }

    let newIndex = 0;
    switch (direction) {
      case 'previous':
        newIndex = selectedEvaluationIndex - 1;
        break;
      case 'next':
        newIndex = selectedEvaluationIndex + 1;
        break;
    }
    setSelectedEvaluationIndex(newIndex);
  };

  return (
    <ModalSidebar
      show={true}
      onClose={() => setSelectedEvaluationIndex(-1)}
      header={''}
      headerClassName="h1"
      headerActions={
        <SidePanelHeaderActions
          onSwitch={onSwitchFeedback}
          canSwitch={canSwitchFeedback}
        />
      }
      size="xl"
      content={<Content evaluation={evaluations[selectedEvaluationIndex]} />}
    />
  );
};

export default FeedbackSidebarDetails;
