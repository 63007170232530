import {
  CandidateEvaluations,
  EvaluationAnswers as EvaluationAnswersGraphQl,
} from '../../types';
import { QuestionAndOptions } from '../types';
import { EvaluationAnswers } from './types';

export const groupQuestionAndOptions = (
  candidateEvaluations: CandidateEvaluations,
): QuestionAndOptions => {
  const questionAndOptions: QuestionAndOptions = {
    questions: {},
    options: {},
  };

  candidateEvaluations.forEach((candidateEvaluation) => {
    candidateEvaluation.evaluationTemplate?.questions.forEach((question) => {
      questionAndOptions.questions[question.Id] = question.Name;
      question.options.forEach((option) => {
        questionAndOptions.options[option.Id] = option.Name;
      });
    });
  });

  return questionAndOptions;
};

export const mapAnswers = (
  answers: EvaluationAnswersGraphQl,
): EvaluationAnswers[] =>
  answers?.map((a) => ({
    answer: a.flair__Answer__c,
    templateOption: a.flair__Evaluation_Template_Option__c,
    templateQuestion: a.flair__Evaluation_Template_Question__c,
    type: a.flair__Question_Type__c,
    score: a.flair__Score__c,
  })) ?? [];
