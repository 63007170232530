import React, { useCallback, useState } from 'react';
import PageHeader from '../../components/PageHeader';
import { useTranslation } from 'react-i18next';
import { Card } from 'react-bootstrap';
import { Item, MyExpensesTable } from './MyExpensesTable';
import {
  collectCategoryFilterOptions,
  collectYearFilterOptions,
  ExpensesFilter,
} from './Filters/ExpensesFilter';
import { ExpenseSidebar } from './ExpenseSidebar/ExpenseSidebar';
import {
  useDeleteExpenseMutation,
  useExpensesQuery,
} from '../../__generated__/graphql';
import { emptyExpenseFilter, Expense, ExpenseFilter } from './types';
import { mapExpense } from './mappings';
import ServerError from '../../../../components/ServerError';
import { SkeletonTable } from '../../components/Skeleton/Table';
import { routes, routes as mainRoutes } from '../../routes';
import { applyExpenseFilter } from './filters';
import { ExpenseDeleteConfirmation } from './DeleteConfirmations';
import { useToasts } from '../../../../context/Toast';
import { useMutationErrorHandler } from '../../../../hooks/useMutationErrorHandler';
import { ExpenseSaveSidebar } from './ExpenseSaveSidebar';
import { useFlairPersistentFilter } from '../../../../hooks/useFlairPersistentFilter';
import { Maybe } from '../../../../utils/maybe';
import { useHistory, useParams } from 'react-router-dom';
import { useFlairBreadcrumbHook } from '../../../../hooks/useFlairBreadcrumbHook';
import {
  createShowCommentQueryParams,
  useSelectedItemWithComments,
} from '../../components/Comment';

const MyExpenses: React.FC = () => {
  const history = useHistory();
  const { id: routeExpenseId } = useParams<{ id: string }>();
  const { defaultSelectedItem: selectedItemWithComments } =
    useSelectedItemWithComments();

  const { data, loading, error } = useExpensesQuery();
  const [deleteExpense, { loading: deleteInProgress }] =
    useDeleteExpenseMutation();
  const errorHandler = useMutationErrorHandler();

  const { t } = useTranslation();

  useFlairBreadcrumbHook([
    { label: t('navigation.menuItems.profile.title') },
    { label: t('expenses.title') },
  ]);

  const [filter, setFilter] = useFlairPersistentFilter<ExpenseFilter>({
    defaultFilter: emptyExpenseFilter,
    storageKey: 'my_expense_filter',
    createRouteUrl: (queryParams) =>
      mainRoutes.myExpenses.route.withQueryParams(queryParams).create({}),
  });
  const { addSuccess, addError } = useToasts();

  const [deleteItemId, setDeleteItemId] = useState<string | null>(null);
  const [saveSideBarVisible, setSaveSideBarVisible] = useState<boolean>(false);

  const expenseDelete = useCallback(async () => {
    if (deleteItemId === null) {
      return;
    }
    await deleteExpense({
      variables: {
        id: deleteItemId,
      },
    })
      .then((r) => {
        if (r.data?.expenses.deleteExpense.error) {
          addError(r.data?.expenses.deleteExpense.error);
          return;
        }
        addSuccess(t('expenses.expenseDeletedToast'));
      })
      .catch(errorHandler)
      .finally(() => {
        setDeleteItemId(null);
      });
  }, [addError, addSuccess, deleteExpense, deleteItemId, errorHandler, t]);

  const handleCreateBtnClick = useCallback(() => {
    setSaveSideBarVisible(true);
  }, []);

  const handleSaveSidebarClose = useCallback(() => {
    setSaveSideBarVisible(false);
  }, []);

  if (error) {
    return <ServerError />;
  }

  const handleDelete = (expenseId: string) => {
    setDeleteItemId(expenseId);
  };

  const handleDeleteClose = () => {
    setDeleteItemId(null);
  };

  let allExpenses: Expense[] = data?.me.myExpenses.map(mapExpense) || [];

  const handleOnItemClick = (item: Maybe<Item>) => {
    if (item?.id) {
      history.push(
        routes.myExpense.route
          .withQueryParams(
            item.showComments
              ? createShowCommentQueryParams({ recordId: item.id })
              : {},
          )
          .create({ id: item.id }),
      );
    } else {
      history.push(routes.myExpenses.route.create({}));
    }
  };

  const renderContent = () => {
    if (loading || !data) {
      return <Loading />;
    }

    const expenses = applyExpenseFilter(filter, allExpenses);

    return (
      <MyExpensesTable
        onItemClick={handleOnItemClick}
        expenses={expenses}
        onDelete={handleDelete}
      />
    );
  };
  const selectedItemId = routeExpenseId ?? selectedItemWithComments?.id;
  const showComments = selectedItemWithComments?.showComments ?? false;
  let item = allExpenses?.find((x) => x.id === selectedItemId) ?? null;
  return (
    <>
      <PageHeader title={t('expenses.title')} />
      <Card>
        <ExpensesFilter
          filter={filter}
          categoryOptions={collectCategoryFilterOptions(allExpenses)}
          yearOptions={collectYearFilterOptions(allExpenses)}
          onChange={setFilter}
          onCreateBtnClick={handleCreateBtnClick}
        />
        {renderContent()}
      </Card>
      {item && (
        <ExpenseSidebar
          isManager={false}
          expense={item}
          onClose={() => handleOnItemClick(null)}
          showComments={showComments}
        />
      )}
      <ExpenseDeleteConfirmation
        visible={!!deleteItemId}
        submitInProgress={deleteInProgress}
        onClose={handleDeleteClose}
        onSubmit={expenseDelete}
      />
      <ExpenseSaveSidebar
        show={saveSideBarVisible}
        onSave={handleSaveSidebarClose}
        onClose={handleSaveSidebarClose}
      />
    </>
  );
};

const Loading: React.FC = () => {
  return <SkeletonTable columns={5} rows={5} />;
};

export default MyExpenses;
