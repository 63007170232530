import React, { useContext, useState } from 'react';
import { BodyProps, CategoryAccordionProps } from '../../types';
import { useAbsenceYearlySummaryQuery } from '../../../../__generated__/graphql';
import ServerError from '../../../../../../components/ServerError';
import Body from './Body';
import { AbsencesContext } from '../../AbsencesContext';
import { mapSummary } from './mappings';
import Loading from '../Loading';
import { parseDate } from '../../../../../../utils/dateUtils';
import { getDefaultStartPeriod } from './helpers';

type Props = Pick<
  CategoryAccordionProps,
  'category' | 'hideRequestButton' | 'categories' | 'isActive'
>;

export const BodyConnected: React.FC<Props> = ({
  category,
  isActive = false,
  ...props
}) => {
  const { employeeId } = useContext(AbsencesContext);

  const { data, error, loading } = useAbsenceYearlySummaryQuery({
    variables: { employeeId },
    skip: !isActive,
  });

  const employee = data?.employee;
  const absenceCategory = employee?.absenceCategories.find(
    (c) => c.Id === category.Id,
  );

  const summary = absenceCategory ? mapSummary(absenceCategory) : null;
  const [startOfPeriod, setStartOfPeriod] = useState<Date | undefined>();

  const defaultStartPeriod = getDefaultStartPeriod(
    new Date(),
    summary?.customYearStartDay
      ? parseDate(summary.customYearStartDay)
      : undefined,
  );

  if (error) {
    return <ServerError />;
  }
  if (loading) {
    return <Loading />;
  }
  if (!summary || !defaultStartPeriod) {
    return null;
  }

  const bodyProps: BodyProps = {
    summary,
    onPeriodChanged: setStartOfPeriod,
    startOfPeriod: startOfPeriod ?? defaultStartPeriod,
    category,
    loading,
    ...props,
  };

  return <Body {...bodyProps} />;
};
