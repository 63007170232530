import moment from 'moment';
import {
  Day,
  TimeEntryBreakChangeRequestProps,
  TimeEntryBreakProps,
  TimeEntryChangeRequestProps,
  TimeEntryProps,
} from '../../../../components/TimeSheet/DayCard/types';
import {
  SubmitTimeEntryChangeRequestInput,
  SubmitTimeEntryInput,
  TimeEntryBreakChangeRequestInput,
  TimeEntryBreakInput,
  TimeEntryChangeRequestFieldsFragment,
  UpdateTimeEntryNotesInput,
} from '../../../../__generated__/graphql';
import {
  DayWithTimeEntries,
  TimeEntryBreakItem,
  TimeEntryItem,
  DateAndTimeIntervalType,
  toDateTime,
  toDateTimeStr,
  isMultiday,
  AutoBreakDeductionStatus,
  EmployeeType,
  PayrollStatus,
} from '.';
import { hoursToMinutes } from '../../../../../../utils/time';

export const mapToDayWithTimeEntries = (
  src: Day,
  employeeType: EmployeeType = 'employee',
): DayWithTimeEntries => ({
  employeeType,
  timeSheetId: src.timeSheetId,
  timeSheetDayId: src.timeSheetDayId,
  commentsCount: src.commentsCount,
  day: src.day,
  warnings: src.warnings,
  absences: src.absences,
  holidays: src.holidays,
  calendarPeriods: src.calendarPeriods,
  workingMinutes: Math.round(
    hoursToMinutes(
      src.currentEntries.reduce(
        (result, entry) => result + entry.flair__Working_Period_In_Hours__c,
        0,
      ),
    ),
  ),
  timeEntries: mapTimeEntries(src, employeeType),
  autoBreakRules: src.autoBreakRules,
  workload: src.workload,
  loomVideo: src.loomVideo || null,
});

export const mapTimeEntries = (
  src: Pick<Day, 'timeSheetId' | 'currentEntries' | 'timeEntries'>,
  employeeType: EmployeeType,
): TimeEntryItem[] => {
  return src.currentEntries.map((x) => {
    const result = mapToTimeEntry(x, src.timeSheetId);
    if (
      isManagerOrCanChangeOwnTimeEntries(employeeType) &&
      result.changeRequestId !== null
    ) {
      const originTimeEntry = src.timeEntries.find(
        (srcTimeEntry) => srcTimeEntry.Id === result.timeEntryId,
      );
      if (originTimeEntry) {
        result.timeEntryOldInterval = mapTimeInterval(
          originTimeEntry.flair__Start_Datetime__c,
          originTimeEntry.flair__End_Datetime__c,
        );
      }
    }
    return result;
  });
};

export const isManagerOrCanChangeOwnTimeEntries = (
  employeeType: EmployeeType,
): boolean =>
  employeeType === 'manager' ||
  employeeType === 'employeeWithCanChangeOwnTimeEntries';

export const mapToTimeEntry = (
  src:
    | TimeEntryProps
    | TimeEntryChangeRequestProps
    | TimeEntryChangeRequestFieldsFragment,
  timeSheetId: string,
): TimeEntryItem => {
  const isChangeRequest = src.__typename === 'TimeEntryChangeRequest';
  const interval = mapTimeInterval(
    src.flair__Start_Datetime__c,
    src.flair__End_Datetime__c,
  );

  return {
    uniqueId: `${src.Id}_${src.LastModifiedDate}`,
    timeEntryId: isChangeRequest
      ? (src as TimeEntryChangeRequestProps).flair__Time_Entry__c
      : src.Id,
    timeSheetId,
    changeRequestId: isChangeRequest ? src.Id : null,
    changeRequestEmployeeId: isChangeRequest
      ? src.flair__Employee__c
      : undefined,
    notes: mapNotes(src),
    costCenterId: src.flair__Cost_Center__c,
    ...interval,
    payrollStatus: mapPayrollStatus(src),
    isMultiday: isMultiday(interval, moment()),
    breaks: mapBreaks(src.breaks, isChangeRequest),
    isNew: false,
    breaksDeductionStatus: calculateDeductionStatus(src),
  };
};

const mapPayrollStatus = (
  src: TimeEntryProps | TimeEntryChangeRequestProps,
): PayrollStatus | undefined => {
  if ('flair__Payroll_Completed__c' in src && src.flair__Payroll_Completed__c) {
    return 'completed';
  }
  if ('flair__Is_Locked__c' in src && src.flair__Is_Locked__c) {
    return 'locked';
  }
  return undefined;
};

export const calculateDeductionStatus = (
  src:
    | TimeEntryProps
    | TimeEntryChangeRequestProps
    | TimeEntryChangeRequestFieldsFragment,
): AutoBreakDeductionStatus => {
  if (src.__typename === 'TimeEntry' || src.__typename === 'MyTimeEntry') {
    if (src.flair__Non_compliant__c === true) {
      return 'failed';
    }
    if (src.breaks.some((x) => x.flair__Auto_adjusted_Datetime__c !== null)) {
      return 'success';
    }
    return undefined;
  }
  return undefined;
};

export const mapTimeEntryBreakItemChangeRequest = (
  src: TimeEntryBreakChangeRequestProps,
): TimeEntryBreakItem => {
  return {
    uniqueId: `${src.Id}_${src.LastModifiedDate}`,
    breakChangeRequestId: src.Id,
    breakId: src.flair__Time_Entry_Break__c ?? null,
    ...mapTimeInterval(
      src.flair__Start_Datetime__c,
      src.flair__End_Datetime__c,
    ),
    isNew: false,
  };
};

export const mapTimeEntryBreakItem = (
  src: TimeEntryBreakProps,
): TimeEntryBreakItem => {
  return {
    uniqueId: `${src.Id}_${src.LastModifiedDate}`,
    breakId: src.Id,
    breakChangeRequestId: null,
    ...mapTimeInterval(
      src.flair__Start_Datetime__c,
      src.flair__End_Datetime__c,
    ),
    isNew: false,
  };
};

const mapTimeInterval = (
  startDateTime: string,
  endDateTime: string | null,
): DateAndTimeIntervalType => {
  return {
    start: toDateTime(startDateTime),
    end: endDateTime ? toDateTime(endDateTime) : null,
  };
};

export const mapToSubmitTimeEntryChangeRequestInput = (
  src: TimeEntryItem,
): SubmitTimeEntryChangeRequestInput => ({
  timeEntryId: src.timeEntryId,
  changeRequestId: src.changeRequestId,
  creationNotes: src.notes.length > 0 ? src.notes.trim() : null,
  startDatetime: toDateTimeStr(src.start),
  endDatetime: src.end ? toDateTimeStr(src.end) : null,
  notes: null, //deprecated
  breaks: src.breaks.map(mapToTimeEntryBreakChangeRequestInput),
  costCenterId: src.costCenterId,
});

export const mapTimeEntryNotesChangedInput = (
  src: TimeEntryItem,
): UpdateTimeEntryNotesInput => ({
  timeEntryId: src.timeEntryId,
  changeRequestId: src.changeRequestId,
  notes: src.notes,
});

const mapToTimeEntryBreakChangeRequestInput = (
  src: TimeEntryBreakItem,
): TimeEntryBreakChangeRequestInput => ({
  breakId: src.breakId,
  breakChangeRequestId: src.breakChangeRequestId,
  startDatetime: toDateTimeStr(src.start),
  endDatetime: src.end ? toDateTimeStr(src.end) : null,
});

export const mapToSubmitTimeEntryInput = (
  src: TimeEntryItem,
  employeeId: string,
): SubmitTimeEntryInput => ({
  employeeId,
  timeEntryId: src.timeEntryId,
  notes: src.notes.length > 0 ? src.notes.trim() : null,
  startDatetime: toDateTimeStr(src.start),
  endDatetime: src.end ? toDateTimeStr(src.end) : null,
  breaks: src.breaks.map(mapToTimeEntryBreakInput),
  costCenterId: src.costCenterId,
});

const mapToTimeEntryBreakInput = (
  src: TimeEntryBreakItem,
): TimeEntryBreakInput => ({
  breakId: src.breakId,
  startDatetime: toDateTimeStr(src.start),
  endDatetime: src.end ? toDateTimeStr(src.end) : null,
});

const mapNotes = (
  src: TimeEntryProps | TimeEntryChangeRequestProps,
): string => {
  const isChangeRequest = src.__typename === 'TimeEntryChangeRequest';
  if (isChangeRequest) {
    const timeEntry = (src as TimeEntryChangeRequestProps).timeEntry;
    return (
      timeEntry?.flair__Notes__c ||
      src.flair__Creation_Time_Entry_Notes__c ||
      ''
    );
  } else {
    return (src as TimeEntryProps).flair__Notes__c || '';
  }
};

const mapBreaks = (
  srcBreaks:
    | ReadonlyArray<TimeEntryBreakProps>
    | ReadonlyArray<TimeEntryBreakChangeRequestProps>,
  isChangeRequest: boolean,
): TimeEntryBreakItem[] => {
  return !isChangeRequest
    ? (srcBreaks as TimeEntryBreakProps[]).map((x) => mapTimeEntryBreakItem(x))
    : (srcBreaks as TimeEntryBreakChangeRequestProps[]).map((x) =>
        mapTimeEntryBreakItemChangeRequest(x),
      );
};
